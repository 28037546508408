import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Outlet, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Icon,
  TextField,
  Paper,
  useTheme,
  IconButton,
  CardHeader,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import "../../assets/css/upload.css";
import * as yup from "yup";

import { IconButtonProps } from "@mui/material/IconButton";

import { OficinasService } from "../../shared/services/api/oficinas/OficinasService";
import { HistoricoReparo, Loading } from "../../shared/components";
import { FerramentasDaListagem } from "../../shared/components/ferramentas-da-listagem/FerramentasDaListagem";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { useAuthContext } from "../../shared/contexts";
import type { TimeLineChamado } from "../../shared/components/historico-reparo/HistoricoReparoProps.types";
import CarCrashOutlinedIcon from "@mui/icons-material/CarCrashOutlined";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

import Collapse from "@mui/material/Collapse";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UploadService } from "../../shared/services/api/upload/UploadService";
import { OrcamentoService } from "../../shared/services/api/orcamento/OrcamentoService";
import { OrcamentoDetalhe } from "../../shared/services/api/orcamento/orcamentos";
import { NumberFormat } from "../../shared/helpers";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const searchSchema = yup.object().shape({
  termo: yup.string().required().min(5),
});

export const CheckInOut = () => {
  const [open, setOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isAlertMessage, setAlertMessage] = useState<any>({
    message: "",
    severity: "",
  });
  const [idReparo, setIdReparo] = useState<number>(0);
  const { accessToken } = useAuthContext();
  const [timeLine, setTimeLine] = useState<TimeLineChamado>({
    cor: "",
    dataProblema: "",
    dataConclusao: "",
    placa: "",
    statusChamado: "",
    veiculo: "",
    relato_consumidor: "",
    checkIn: [],
    checkOut: [],
  });

  const [termoError, setTermoError] = useState("");
  const [termo, setTermo] = useState("");
  const [tipoCheck, setTipoCheck] = useState("");
  const theme = useTheme();
  const { tipo } = useParams() || "";

  const [expanded, setExpanded] = React.useState(true);
  const [expandedUpload, setExpandedUpload] = React.useState(true);
  const [expandedHistory, setExpandedHistory] = React.useState(true);
  const [expandedOrcamento, setExpandedOrcamento] = React.useState(true);
  const [files, setFiles] = useState<File[]>([]);
  const [descricaoOrcamento, setDescricaoOrcamento] = useState("");
  const [valorOrcamento, setValorOrcamento] = useState(0);
  const [orcamentos, setOrcamentos] = useState<OrcamentoDetalhe[]>([]);
  const [dadosOficina, setDadosOficina] = useState<any>({});
  const [dadosUser, setDadosUser] = useState<any>({});

  useEffect(() => {
    const localUser = localStorage.getItem("app-oficinas__user") || false;
    const localOficina = localStorage.getItem("app-oficinas__oficina");

    (async () => {
      if (localUser) {
        setDadosUser(JSON.parse(localUser));

        if (localOficina) {
          setDadosOficina(JSON.parse(localOficina));
        }
      }
    })();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandUploadClick = () => {
    setExpandedUpload(!expandedUpload);
  };

  const handleExpandHistoryClick = () => {
    setExpandedHistory(!expandedHistory);
  };
  const handleExpandOrcamentoClick = () => {
    setExpandedOrcamento(!expandedOrcamento);
  };

  const handlePesquisar = async () => {
    setIsLoading(true);
    setTipoCheck(tipo || "");
    searchSchema
      .validate({ termo }, { abortEarly: false })
      .then((data) => {
        OficinasService.searchChamados(
          dadosOficina?.ofi_id,
          data.termo,
          tipo || ""
        ).then((result) => {
          let type = "";

          if (result instanceof Error || !result?.status) {
            type = "error";
            setIsSearch(false);
            setIsLoading(false);
            setOpen(true);
          } else {
            type = "success";
            setIsSearch(true);
            setIdReparo(result?.data?.repa_id);
            setTimeLine({
              cor: result?.data?.sta_cor,
              dataProblema: result?.data?.repa_data_problema,
              dataConclusao: result?.data?.repa_conclusao,
              placa: result?.data?.repa_placa,
              statusChamado: result?.data?.sta_nome,
              veiculo: result?.data?.repa_veiculo,
              relato_consumidor: result?.data?.repa_relato_consumidor,
              checkIn: result?.data?.checkIn,
              checkOut: result?.data?.checkOut,
            });
            setOpen(false);
            setIsLoading(false);
            setIsDisabled(false);
            loadOrcamentos(result?.data?.repa_id);
          }

          setAlertMessage({
            message: result?.message,
            severity: type === "" || type === "error" ? "error" : "success",
          });
        });
      })
      .catch((errors: yup.ValidationError) => {
        errors.inner.forEach((error) => {
          if (error.path === "termo") {
            setTermo(error.message);
          }
        });
      });
  };

  const handleOnChange = (termo: string) => {
    if (termo.length > 4) {
      setIsSearchDisabled(false);
    } else {
      setIsSearchDisabled(true);
    }
    setTermo(termo);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = () => {
    setIsDisabled(true);
    setIsLoading(true);

    OficinasService.setCheckinOut(
      accessToken,
      tipoCheck,
      dadosOficina?.ofi_id,
      idReparo,
      dadosUser?.users?.id
    ).then((result) => {
      let type = "";
      if (result instanceof Error || !result?.status) {
        type = "error";
        setOpen(true);
        setIsLoading(false);
      } else {
        type = "success";
        setIsLoading(false);
        setOpen(true);
      }

      setAlertMessage({
        message: result?.message,
        severity: type,
      });
    });
  };

  const uploadState = (event: any) => {
    setFiles(event?.target?.files);
  };

  const handleUpload = async (event: any) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("file[]", files[i], files[i]["name"]);
    }

    UploadService.enviarArquivos(accessToken, idReparo, formData).then(
      (result) => {
        let type = "";
        let message = "";
        if (result instanceof Error || !result?.status) {
          type = "error";
          message = "Erro ao enviar arquivo(s).";
          setOpen(true);
          setIsLoading(false);
        } else {
          type = "success";
          message = "Sucesso ao enviar arquivo(s).";
          setIsLoading(false);
          setOpen(true);
        }
        setAlertMessage({
          message: message,
          severity: type,
        });
      }
    );
  };

  const loadOrcamentos = async (repa_id: number) => {
    OrcamentoService.getOrcamentos(accessToken, repa_id).then((result) => {
      if (result instanceof Error) {
        setOrcamentos([]);
      } else {
        setOrcamentos(result);
      }
    });
  };

  const handleSalvarOrcamento = async () => {
    if (descricaoOrcamento === "") {
      setOpen(true);
      setAlertMessage({
        message:
          "Para adicionar um item de orçamento, favor informar a sua descrição.",
        severity: "error",
      });
      return;
    }

    if (valorOrcamento === 0) {
      setOpen(true);
      setAlertMessage({
        message:
          "Para adicionar um item de orçamento, favor informar o seu valor.",
        severity: "error",
      });
      return;
    }

    OrcamentoService.setOrcamento(
      accessToken,
      idReparo,
      descricaoOrcamento,
      valorOrcamento
    ).then((result) => {
      let type = "";
      let message = "";
      if (result instanceof Error || !result?.status) {
        type = "error";
        message = "Erro ao sarvar item do orçamento.";
        setOpen(true);
        setIsLoading(false);
      } else {
        type = "success";
        message = "Sucesso ao salvar item do orçamento.";
        setIsLoading(false);
        setOpen(true);
        loadOrcamentos(idReparo);
        setDescricaoOrcamento("");
        setValorOrcamento(0);
      }
      setAlertMessage({
        message: message,
        severity: type,
      });
    });
  };

  const handleDeleteOrcamento = async (orc_id: number) => {
    OrcamentoService.deleteOrcamento(accessToken, orc_id).then((result) => {
      let type = "";
      let message = "";
      if (result instanceof Error || !result?.status) {
        type = "error";
        message = "Erro ao deletar item do orçamento.";
        setOpen(true);
        setIsLoading(false);
      } else {
        type = "success";
        message = "Sucesso ao deletar item do orçamento.";
        setIsLoading(false);
        setOpen(true);
        loadOrcamentos(idReparo);
      }
      setAlertMessage({
        message: message,
        severity: type,
      });
    });
  };

  return (
    <>
      <LayoutBaseDePagina
        titulo="Dashboard"
        barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
      >
        <Box width="100%" display="flex">
          <Grid container margin={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>
                  <CardContent>
                    <Typography
                      color="textSecondary"
                      variant="subtitle1"
                      align="left"
                    >
                      Digite a <b>placa</b> ou o{" "}
                      <b>certificado de Garantia Mecânica</b> para verificar o
                      status do veículo.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  marginX={0}
                  padding={1}
                  display="flex"
                  alignItems="center"
                  height={theme.spacing(6)}
                  component={Paper}
                >
                  <TextField
                    fullWidth
                    type="termo"
                    value={termo}
                    placeholder="Pesquisar..."
                    disabled={isLoading}
                    error={!!termoError}
                    helperText={termoError}
                    onKeyDown={() => setTermoError("")}
                    onChange={(e) => handleOnChange(e.target.value)}
                    sx={{ mr: 2 }}
                  />
                  <Box flex={1} display="flex" justifyContent="end">
                    <Button
                      variant="contained"
                      disabled={isSearchDisabled}
                      onClick={handlePesquisar}
                      endIcon={<Icon>search</Icon>}
                      sx={{
                        color: "#fff",
                        backgroundColor: "#36B34A",
                        padding: 2,
                        margin: 0,
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              {isSearch && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card sx={{ mb: 2 }}>
                    <CardHeader
                      sx={{ m: "0 0 1rem 0", p: "1rem 1.5rem 0 1.5rem" }}
                      title="Dados do veículo:"
                      action={
                        <ExpandMore
                          expand={expanded}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="Mostrar mais"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      }
                    />
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent sx={{ pt: 0, mt: 0 }}>
                        <Typography color="textSecondary" variant="body2">
                          <b>Veículo: </b>
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            textTransform: "capitalize",
                            alignItems: "flex-start",
                            fontSize: "0.9rem",
                          }}
                        >
                          <DirectionsCarIcon
                            fontSize="small"
                            sx={{ mr: 1, color: "#2e7d32" }}
                          />
                          {timeLine?.veiculo?.toLocaleLowerCase()}
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          sx={{ mt: 2 }}
                        >
                          <b>Relato Consumidor: </b>
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            textTransform: "capitalize",
                            alignItems: "flex-start",
                            fontSize: "0.9rem",
                          }}
                        >
                          <CarCrashOutlinedIcon
                            fontSize="small"
                            sx={{ mr: 1, color: "red" }}
                          />
                          {timeLine?.relato_consumidor?.toLocaleLowerCase()}
                        </Typography>
                      </CardContent>
                    </Collapse>
                  </Card>

                  <Card sx={{ mb: 2 }}>
                    <CardHeader
                      sx={{ m: "0 0 1rem 0", p: "1rem 1.5rem 0 1.5rem" }}
                      title="Envio de arquivo:"
                      action={
                        <ExpandMore
                          expand={expandedUpload}
                          onClick={handleExpandUploadClick}
                          aria-expanded={expandedUpload}
                          aria-label="Mostrar mais"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      }
                    />
                    <Collapse in={expandedUpload} timeout="auto" unmountOnExit>
                      <CardContent sx={{ pt: 0, mt: 0 }}>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          mb={2}
                        >
                          <b>
                            Anexe aqui o documento para andamento do chamado{" "}
                          </b>
                        </Typography>

                        <input
                          type="file"
                          style={{ border: "none" }}
                          onChange={uploadState}
                        />

                        <Button
                          sx={{ ml: "40px" }}
                          variant="contained"
                          component="label"
                          onClick={handleUpload}
                        >
                          Enviar Arquivo
                        </Button>
                      </CardContent>
                    </Collapse>
                  </Card>

                  <Card sx={{ mb: 2 }}>
                    <CardHeader
                      sx={{ m: "0 0 1rem 0", p: "1rem 1.5rem 0 1.5rem" }}
                      title="Orçamento de oficina:"
                      action={
                        <ExpandMore
                          expand={expandedOrcamento}
                          onClick={handleExpandOrcamentoClick}
                          aria-expanded={expandedOrcamento}
                          aria-label="Mostrar mais"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      }
                    />
                    <Collapse
                      in={expandedOrcamento}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent sx={{ pt: 0, mt: 0 }}>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          mb={2}
                        >
                          <b>
                            Insira as informações referentes as peças utilizadas{" "}
                          </b>
                        </Typography>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Box
                            marginX={0}
                            padding={1}
                            display="flex"
                            alignItems="center"
                            height={theme.spacing(6)}
                            component={Paper}
                          >
                            <TextField
                              type="desc"
                              placeholder="Descrição da peça"
                              sx={{ mr: 2, width: 500 }}
                              value={descricaoOrcamento}
                              onChange={(event: any) => {
                                setDescricaoOrcamento(event.target.value);
                              }}
                            />
                            <TextField
                              type="number"
                              value={valorOrcamento}
                              placeholder="Valor(R$)"
                              sx={{ mr: 2 }}
                              inputProps={{
                                step: 0.01,
                              }}
                              onChange={(event: any) => {
                                setValorOrcamento(
                                  parseFloat(event.target.value)
                                );
                              }}
                            />
                            <Box flex={1} display="flex">
                              <Button
                                variant="contained"
                                endIcon={<Icon>add</Icon>}
                                sx={{
                                  color: "#284614",
                                  backgroundColor: theme.palette.warning.light,
                                  padding: 2,
                                }}
                                onClick={handleSalvarOrcamento}
                              />
                            </Box>
                          </Box>
                          <Box
                            mt={2}
                            padding={1}
                            display="flex"
                            alignItems="center"
                            height={theme.spacing(6)}
                          >
                            <Typography
                              color="textSecondary"
                              variant="body2"
                              mb={0}
                            >
                              <b>Ítens adicionados: </b>
                            </Typography>
                          </Box>
                          {orcamentos.map((orcamento: OrcamentoDetalhe) => (
                            <Box
                              mb={1}
                              padding={1}
                              display="flex"
                              alignItems="center"
                              bgcolor="#e9e9e9"
                              key={orcamento.orc_id}
                            >
                              <Typography
                                sx={{
                                  display: "flex",
                                  textTransform: "capitalize",
                                  alignItems: "flex-start",
                                  fontSize: "0.9rem",
                                  mr: 5,
                                  width: 600,
                                }}
                              >
                                {orcamento.orc_descricao}
                              </Typography>
                              <Typography
                                sx={{
                                  display: "flex",
                                  textTransform: "capitalize",
                                  alignItems: "flex-start",
                                  fontSize: "0.9rem",
                                  mr: 5,
                                }}
                              >
                                {NumberFormat(orcamento.orc_vlr_orcado)}
                              </Typography>
                            </Box>
                          ))}
                        </Grid>
                      </CardContent>
                    </Collapse>
                  </Card>

                  <Card>
                    <CardHeader
                      sx={{ m: "0 0 1rem 0", p: "1rem 1.5rem 0 1.5rem" }}
                      title="Histórico do Chamado:"
                      action={
                        <ExpandMore
                          expand={expandedHistory}
                          onClick={handleExpandHistoryClick}
                          aria-expanded={expandedHistory}
                          aria-label="Mostrar mais"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      }
                    />
                    <Collapse in={expandedHistory} timeout="auto" unmountOnExit>
                      <CardContent sx={{ pt: 0, mt: 0 }}>
                        <HistoricoReparo
                          dataProblema={timeLine.dataProblema}
                          checkIn={timeLine?.checkIn || null}
                          checkOut={timeLine?.checkOut || null}
                        />
                      </CardContent>
                    </Collapse>
                  </Card>
                  {(tipoCheck === "in" ||
                    (tipoCheck === "out" &&
                      timeLine?.dataConclusao !== null &&
                      timeLine?.checkOut === null)) && (
                    <Box sx={{ display: "block", "& button": { mt: 2 } }}>
                      <Button
                        size="large"
                        variant="contained"
                        disabled={isDisabled}
                        onClick={handleCheck}
                        sx={{
                          color: "#fff",
                          backgroundColor: "#36B34A",
                          width: "100%",
                        }}
                      >
                        Realizar o{" "}
                        {tipoCheck === "out" ? "check-out" : "Check-in"}
                      </Button>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
        {isLoading && <Loading open={isLoading} onClick={handleClose} />}
        {open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            key={"top" + "center"}
          >
            <Alert
              onClose={handleClose}
              severity={isAlertMessage?.severity}
              variant="filled"
              sx={{
                width: "100%",
                whiteSpace: "unset",
                wordBreak: "break-all",
              }}
            >
              {isAlertMessage.message}
            </Alert>
          </Snackbar>
        )}
      </LayoutBaseDePagina>
      <Outlet />
    </>
  );
};
