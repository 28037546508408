import { useEffect, useState } from "react";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Card,
  CircularProgress,
  CardContent,
  Skeleton,
  Stack,
  Paper,
  Typography,
  Chip,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListSubheader from "@mui/material/ListSubheader";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { LayoutBaseDePagina } from "../../shared/layouts";

import { OrcamentoService } from "../../shared/services/api/orcamento/OrcamentoService";
import type { Reparos } from "../../shared/services/api/orcamento/orcamentos";
import { OrcamentoDetalhe } from "../../shared/services/api/orcamento/orcamentos";
import { NumberFormat } from "../../shared/helpers";

export const OrcamentoDetalhes = () => {
  const [detalhes, setDetalhes] = useState<any>([]);
  const [orcamentos, setOrcamentos] = useState<any>([]);
  const [valorTotal, setValorTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { status, id_reparo } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const localUser = localStorage.getItem("app-oficinas__user") || false;
    const localOficina = localStorage.getItem("app-oficinas__oficina");
    const isDadosOficina = !!localOficina ?? false;

    (async () => {
      if (localUser) {
        const dataUser = JSON.parse(localUser);

        if (isDadosOficina) {
          await handleDetalhes(Number(id_reparo) || 0, dataUser?.users?.id);
        }
      }
    })();
  }, []);

  const handleDetalhes = async (id_reparo: number, user_id: number) => {
    OrcamentoService.getDetalhesOrcamento(id_reparo, user_id).then((res) => {
      if (res instanceof Error) {
        setDetalhes([]);
      } else {
        setDetalhes(res?.reparo || {});
        setOrcamentos(res?.orcamentos || []);
        setValorTotal(res?.reparo?.vlr_total || 0);
      }
      console.log({ res });
      setIsLoading(false);
    });
  };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const Status = ({ sta_nome = "", sta_cor = "gray" }: Reparos) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pl: 2, py: 1, bgcolor: "transparent" }}
      >
        <Chip
          label={sta_nome}
          sx={{ bgcolor: sta_cor, fontSize: "0.7rem" }}
          component="span"
        />
      </Stack>
    );
  };

  return (
    <>
      <LayoutBaseDePagina titulo="Dashboard" barraDeFerramentas={null}>
        <Box width="100%" display="flex">
          <Grid container margin={2}>
            <Grid item container spacing={2}>
              {isLoading ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Skeleton height={35} />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Grid>
              ) : (
                detalhes && (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Card
                          sx={{
                            backgroundColor: "#FAD22D",
                            maxWidth: "100%",
                            display: "block",
                          }}
                        >
                          <CardContent
                            sx={{
                              px: "16px",
                              pt: "16px",
                              pb: "16px !important",
                            }}
                          >
                            <Grid
                              item
                              container
                              sx={{
                                alignItems: "center",
                                justifyContent: "space-between",
                                pb: 0,
                              }}
                            >
                              <Typography
                                variant="h6"
                                component="div"
                                sx={{ color: "#1E3C82", fontWeight: "medium" }}
                              >
                                {detalhes.repa_placa}
                              </Typography>
                              {Status(detalhes)}
                            </Grid>
                            <Typography sx={{ my: "5px" }} variant="subtitle1">
                              {detalhes.veiculo}
                            </Typography>
                            <Grid
                              item
                              container
                              sx={{
                                alignItems: "center",
                                display: "flex",
                                p: 0,
                                mb: 0,
                              }}
                            >
                              <Grid item md={12}>
                                {detalhes?.repa_conclusao && (
                                  <>
                                    <Typography
                                      variant="body2"
                                      component="span"
                                    >
                                      Data Conclusão: &nbsp;
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      sx={{ color: "green", fontWeight: "900" }}
                                    >
                                      {detalhes.repa_conclusao}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Box>
                      <List
                        component={Paper}
                        sx={{
                          width: "100%",
                          borderRadius: "15px",
                          bgcolor: "background.paper",
                          my: 2,
                        }}
                        subheader={
                          <ListSubheader
                            sx={{ color: "#2e7d32", fontWeight: "900" }}
                          >
                            Certificado de garantia
                          </ListSubheader>
                        }
                      >
                        <ListItem sx={{ py: 0 }}>
                          <ListItemAvatar sx={{ minWidth: "32px" }}>
                            <BeenhereOutlinedIcon color="primary" />
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ my: 0, fontSize: "0.8em !important" }}
                            primary={detalhes.certificado}
                            secondary={`Data de emissão: ${detalhes.data_ativacao}`}
                            primaryTypographyProps={{
                              fontSize: 14,
                              color: "#1E3C82",
                            }}
                          />
                        </ListItem>
                      </List>
                      <List
                        component={Paper}
                        sx={{
                          width: "100%",
                          borderRadius: "15px",
                          backgroundColor: "rgb(244, 246, 248)",
                          my: 2,
                        }}
                        subheader={
                          <ListSubheader
                            sx={{
                              fontWeight: "900",
                              backgroundColor: "rgb(244, 246, 248)",
                            }}
                          >
                            Proprietário
                          </ListSubheader>
                        }
                      >
                        <ListItem sx={{ py: 0 }}>
                          <ListItemText
                            sx={{ my: 0, fontSize: "0.8em !important" }}
                            primary={detalhes.proprietario}
                            secondary={`Km atual do veículo: ${
                              detalhes?.kma || "-----"
                            } km`}
                            primaryTypographyProps={{
                              fontSize: 14,
                              color: "#1E3C82",
                            }}
                          />
                        </ListItem>
                      </List>
                      <List
                        component={Paper}
                        sx={{
                          width: "100%",
                          borderRadius: "15px",
                          bgcolor: "background.paper",
                          my: 2,
                        }}
                        subheader={
                          <ListSubheader
                            sx={{ color: "#2e7d32", fontWeight: "900" }}
                          >
                            Relato do consumidor
                          </ListSubheader>
                        }
                      >
                        <ListItem sx={{ py: 0 }}>
                          <ListItemText
                            sx={{ my: 0, fontSize: "0.8em !important" }}
                            primary={detalhes.relato_consumidor}
                            primaryTypographyProps={{
                              fontSize: 14,
                              color: "#1E3C82",
                            }}
                          />
                        </ListItem>
                      </List>
                      <List
                        component={Paper}
                        sx={{
                          width: "100%",
                          borderRadius: "15px",
                          bgcolor: "background.paper",
                          my: 2,
                        }}
                        subheader={
                          <ListSubheader
                            sx={{ color: "#2e7d32", fontWeight: "900" }}
                          >
                            Diagnóstico da Oficina
                          </ListSubheader>
                        }
                      >
                        <ListItem sx={{ py: 0 }}>
                          <ListItemText
                            sx={{ my: 0, fontSize: "0.8em !important" }}
                            primary={detalhes.laudo_tecnico}
                            primaryTypographyProps={{
                              fontSize: 14,
                              color: "#1E3C82",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      sx={{ pt: "0 !important" }}
                    >
                      <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
                        <Paper
                          color="text.secondary"
                          sx={{
                            my: 0,
                            mx: "auto",
                            px: "1rem",
                          }}
                        >
                          <Typography
                            display="flex"
                            variant="h6"
                            sx={{
                              color: "#2e7d32",
                              py: 2,
                              mb: 1,
                              justifyContent: "space-between",
                            }}
                          >
                            Itens do orçamento
                            {open ? (
                              <ExpandLess onClick={handleClickOpen} />
                            ) : (
                              <ExpandMore onClick={handleClickOpen} />
                            )}
                          </Typography>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Stack
                              display="flex"
                              flexDirection="column"
                              gap={2}
                              sx={{ py: 2 }}
                            >
                              <Stack
                                display="flex"
                                flexDirection="row"
                                sx={{ justifyContent: "space-between" }}
                              >
                                <Typography component="p" variant="body1">
                                  Descrição
                                </Typography>
                                <Typography component="p" variant="body1">
                                  Valor (R$)
                                </Typography>
                              </Stack>
                              {orcamentos ? (
                                orcamentos.map(
                                  (orcamento: OrcamentoDetalhe) => (
                                    <>
                                      <Stack
                                        display="flex"
                                        flexDirection="row"
                                        sx={{ justifyContent: "space-between" }}
                                        key={orcamento.orc_id}
                                      >
                                        <Typography
                                          component="p"
                                          variant="body2"
                                          sx={{ textTransform: "capitalize" }}
                                        >
                                          {orcamento?.orc_descricao}
                                        </Typography>
                                        <Typography
                                          component="p"
                                          variant="body2"
                                          sx={{
                                            color: "#1E3C82",
                                            fontWeight: "900",
                                          }}
                                        >
                                          {NumberFormat(
                                            orcamento?.orc_vlr_orcado
                                          )}
                                        </Typography>
                                      </Stack>
                                    </>
                                  )
                                )
                              ) : (
                                <CircularProgress color="success" />
                              )}
                              {orcamentos && (
                                <Typography
                                  display="flex"
                                  variant="h5"
                                  sx={{
                                    justifyContent: "right",
                                    color: "#1E3C82",
                                  }}
                                >
                                  {NumberFormat(valorTotal)}
                                </Typography>
                              )}
                            </Stack>
                          </Collapse>
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        sx={{
                          display: "block",
                          width: "100%",
                          "& button": { mt: 2 },
                        }}
                      >
                        <Button
                          size="large"
                          variant="contained"
                          onClick={() =>
                            navigate(`/orcamentos/${status}`, { replace: true })
                          }
                          sx={{
                            color: "rgba(255, 255, 255, 0.8)",
                            background: "rgba(22, 28, 36, 0.48)",
                            width: "100%",
                          }}
                        >
                          Voltar
                        </Button>
                      </Box>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </LayoutBaseDePagina>
      <Outlet />
    </>
  );
};
