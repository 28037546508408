import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  CircularProgress,
  Container,
  InputAdornment,
  TextField,
} from "@mui/material";
import * as yup from "yup";

import { EmailOutlined, LockOutlined } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";

import { useAuthContext } from "../../shared/contexts";
import { LogoLogin } from "../../shared/components/logo/logo-login";

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required().min(5),
});

export const Login = () => {
  const { signIn } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isErro, setIsErro] = useState<string>("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);

    loginSchema
      .validate({ email, password }, { abortEarly: false })
      .then(async (dadosValidados) => {
        await signIn(dadosValidados.email, dadosValidados.password)
          .then((res) => {
            if (res !== undefined) {
              setIsLoading(false);
              setIsErro(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);

        errors.inner.forEach((error) => {
          if (error.path === "email") {
            setEmailError(error.message);
          } else if (error.path === "password") {
            setPasswordError(error.message);
          }
        });
      })
      .finally(() => {
        setIsLoading(false);
        navigate("/dashboard");
      });
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          minHeight: "100%",
        }}
      >
        <Grid>
          <Box
            alignSelf="center"
            justifyContent="center"
            sx={{
              minHeight: "20vh",
            }}
          >
            <LogoLogin />
          </Box>
          <Box
            alignItems="baseline"
            alignSelf="center"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <TextField
              fullWidth
              type="email"
              label="Email"
              value={email}
              disabled={isLoading}
              error={!!emailError}
              helperText={emailError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlined />
                  </InputAdornment>
                ),
              }}
              onKeyDown={() => setEmailError("")}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                mb: 4,
              }}
            />
            <TextField
              fullWidth
              label="Senha"
              type="password"
              value={password}
              disabled={isLoading}
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
              onKeyDown={() => setPasswordError("")}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          {isErro && (
            <Box sx={{ mt: 2 }}>
              <Alert
                severity="error"
                variant="filled"
                onClick={() => {
                  setIsErro("");
                }}
                sx={{
                  width: "92%",
                }}
              >
                {isErro}
              </Alert>
            </Box>
          )}
          <Button
            size="large"
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}
            endIcon={
              isLoading ? (
                <CircularProgress
                  variant="indeterminate"
                  color="inherit"
                  size={20}
                />
              ) : undefined
            }
            sx={{
              mt: 5,
              width: "100%",
              fontSize: "1.1rem",
            }}
          >
            Login
          </Button>
        </Grid>
      </Container>
      <Outlet />
    </>
  );
};
