import axios from "axios";
import Cookies from "js-cookie";
import config from "../../../config";
import { onError } from "./interceptors";


const { bff: { baseUrl } } = config;

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${Cookies.get("app-oficinas__Token")}`;
    config.headers.Authorization = `Bearer ${Cookies.get("app-oficinas__Token")}`;
    return config;
  }, error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => onError(error || error?.message)
);

export const appService = axios.create({
  baseURL: baseUrl
});

export const builder = (resource: string) => {
  return "/api/v1/" + resource;
};
