import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { LayoutBaseDePagina } from "../../shared/layouts";
import { NumberFormat } from "../../shared/helpers";

import { OficinasService } from "../../shared/services/api/oficinas/OficinasService";
import { FerramentasDaListagem } from "../../shared/components/ferramentas-da-listagem/FerramentasDaListagem";

export const Ellipse: React.FC = () => (
  <Box display="flex" alignItems="center" justifyContent="center">
    <svg
      width="64"
      height="63"
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.1098 31.4128C63.1098 37.9764 61.0539 44.375 57.2308 49.7101C53.4077 55.0452 48.0094 59.0488 41.7943 61.1586C35.5791 63.2684 28.8591 63.3783 22.5783 61.4731C16.2974 59.5678 10.7711 55.743 6.77545 50.5358C2.77984 45.3286 0.515659 39.0006 0.300907 32.4406C0.0861549 25.8806 1.93162 19.4182 5.57811 13.9608C9.2246 8.50345 14.4889 4.32537 20.6318 2.01338C26.7746 -0.298619 33.4873 -0.628394 39.8272 1.07037L35.7621 16.2416C32.5921 15.3922 29.2358 15.5571 26.1643 16.7131C23.0929 17.8691 20.4608 19.9581 18.6375 22.6868C16.8143 25.4155 15.8915 28.6467 15.9989 31.9267C16.1063 35.2067 17.2384 38.3707 19.2362 40.9743C21.234 43.5779 23.9972 45.4903 27.1376 46.443C30.278 47.3956 33.638 47.3406 36.7456 46.2857C39.8532 45.2308 42.5523 43.229 44.4638 40.5615C46.3754 37.8939 47.4034 34.6946 47.4034 31.4128H63.1098Z"
        fill="white"
      />
      <path
        d="M63.1098 31.4128C63.1098 35.538 62.2973 39.6228 60.7186 43.434C59.14 47.2452 56.8261 50.7081 53.9092 53.6251C50.9922 56.542 47.5293 58.8559 43.7181 60.4345C39.9069 62.0132 35.8221 62.8257 31.6969 62.8257C27.5717 62.8257 23.4869 62.0132 19.6758 60.4345C15.8646 58.8559 12.4016 56.542 9.4847 53.6251C6.56774 50.7081 4.25389 47.2452 2.67525 43.434C1.0966 39.6228 0.284088 35.538 0.284088 31.4128H15.9905C15.9905 33.4754 16.3968 35.5178 17.1861 37.4234C17.9754 39.329 19.1323 41.0605 20.5908 42.519C22.0493 43.9774 23.7808 45.1344 25.6863 45.9237C27.5919 46.713 29.6343 47.1193 31.6969 47.1193C33.7595 47.1193 35.8019 46.713 37.7075 45.9237C39.6131 45.1344 41.3446 43.9774 42.803 42.519C44.2615 41.0605 45.4185 39.329 46.2078 37.4234C46.9971 35.5178 47.4034 33.4754 47.4034 31.4128H63.1098Z"
        fill="#215B12"
      />
      <path
        d="M31.6969 -8.01086e-05C37.416 -8.01086e-05 43.0266 1.56125 47.9236 4.51552C52.8206 7.4698 56.8181 11.7049 59.4851 16.7641C62.1521 21.8233 63.3873 27.5146 63.0575 33.2242C62.7277 38.9338 60.8454 44.445 57.6137 49.1635L44.6553 40.2881C46.2712 37.9289 47.2123 35.1733 47.3772 32.3185C47.5421 29.4637 46.9245 26.618 45.591 24.0884C44.2575 21.5588 42.2587 19.4413 39.8102 17.9641C37.3618 16.487 34.5565 15.7063 31.6969 15.7063L31.6969 -8.01086e-05Z"
        fill="#FAD22E"
      />
    </svg>
  </Box>
);

export default function FolderList() {
  const navigate = useNavigate();

  return (
    <List sx={{ width: "100%", bgcolor: "#F2F2F2" }}>
      <ListItem>
        <ListItemButton
          sx={{ justifyContent: "end" }}
          onClick={() => navigate("/check/in", { replace: true })}
        >
          <ListItemText
            primary="Fazer Check-In"
            secondary="Entrada na Oficina"
          />
          <IconButton edge="end" aria-label="acessar">
            <ChevronRightIcon />
          </IconButton>
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton
          sx={{ justifyContent: "end" }}
          onClick={() => navigate("/check/out", { replace: true })}
        >
          <ListItemText
            primary="Fazer Check-Out"
            secondary="Saída da Oficina"
          />
          <IconButton edge="end" aria-label="acessar">
            <ChevronRightIcon />
          </IconButton>
        </ListItemButton>
      </ListItem>
      <ListItem>
        <ListItemButton
          sx={{ justifyContent: "end" }}
          onClick={() => navigate("/orcamentos", { replace: true })}
        >
          <ListItemText
            primary="Enviar Orçamento"
            secondary="Peças e Mão de Obra"
          />
          <IconButton edge="end" aria-label="acessar">
            <ChevronRightIcon />
          </IconButton>
        </ListItemButton>
      </ListItem>
    </List>
  );
}

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dadosOficina, setDadosOficina] = useState<any>({});
  const [valores, setValores] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    const localUser = localStorage.getItem("app-oficinas__user") || false;
    const localOficina = localStorage.getItem("app-oficinas__oficina");
    const localValores = localStorage.getItem("app-oficinas__valores");
    const isDadosOficina = (!!localOficina && !!localValores) ?? false;

    (async () => {
      if (!!localOficina && !!localValores) {
        setDadosOficina(JSON.parse(localOficina));
        setValores(JSON.parse(localValores));
        setIsLoading(false);
      }

      if (localUser) {
        const dataUser = JSON.parse(localUser);
        if (!isDadosOficina) {
          await handleDadosOficina(dataUser?.users?.id).then((res) => {
            if (res) setIsLoading(false);
          });
        }
      }
    })();
  }, []);

  const handleDadosOficina = async (user_id: number) => {
    const resOficina = await OficinasService.getDadosCadastro(user_id);

    if (resOficina instanceof Error) {
      return;
    }
    const localOficina = localStorage.getItem("app-oficinas__oficina");

    if (localOficina) setDadosOficina(JSON.parse(localOficina));

    let resValores = {};
    if (resOficina) {
      resValores = await OficinasService.getValoresReparos(
        resOficina?.data?.ofi_id,
        user_id
      );
      const localValores = localStorage.getItem("app-oficinas__valores");
      if (localValores) setValores(JSON.parse(localValores));
    }

    return !!resValores;
  };

  const listSegmentoValores = (key: string) => {
    return (
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={key}>
        <Card
          sx={{
            backgroundColor: "#F2F2F2",
            maxWidth: "100%",
            display: "block",
            p: "1em",
          }}
        >
          <Link
            underline="none"
            onClick={() => navigate(`/orcamentos/${key}`, { replace: true })}
          >
            <Typography variant="h4" align="center" sx={{ color: "#323232" }}>
              {valores[key]?.qnte < 999 ? valores[key]?.qnte : "+1K"}
            </Typography>
            <Typography
              variant="body2"
              align="center"
              lineHeight="1.1"
              sx={{ color: "#323232" }}
            >
              <small>{valores[key]?.texto}</small>
            </Typography>
          </Link>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <LayoutBaseDePagina
        titulo="Dashboard"
        barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
      >
        <Box width="100%" display="flex">
          <Grid container margin={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card
                  sx={{
                    backgroundColor: "#36B34A",
                    maxWidth: "100%",
                    display: "block",
                  }}
                >
                  <CardContent
                    sx={{
                      p: "16px !important",
                    }}
                  >
                    {isLoading ? (
                      <>
                        <Skeleton height={35} />
                        <Skeleton />
                      </>
                    ) : (
                      <Box
                        display="flex"
                        alignSelf="center"
                        justifyContent="left"
                      >
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          align="left"
                        >
                          <Icon
                            sx={{
                              color: "#fff",
                              fontSize: 48,
                            }}
                          >
                            account_circle
                          </Icon>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                          }}
                        >
                          Olá,
                          <Typography
                            sx={{
                              color: "#FAD22E",
                              fontSize: "0.8rem",
                            }}
                          >
                            {dadosOficina?.ofi_fantasia}.
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box
                    display="flex"
                    sx={{ mt: 2, ml: 0, justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{
                        color: "#323232",
                      }}
                    >
                      STATUS DE SINISTRO
                    </Typography>
                    <Typography
                      sx={{
                        color: "#21512",
                        fontSize: "0.7rem",
                      }}
                    >
                      Últimos 90 dias
                    </Typography>
                  </Box>
                </Grid>
                {!isLoading && listSegmentoValores("aprovados")}
                {!isLoading && listSegmentoValores("pendentes")}
                {!isLoading && listSegmentoValores("enviados")}
              </Grid>
              {isLoading ? (
                <>
                  <Skeleton height={35} />
                </>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card
                    sx={{
                      backgroundColor: "#36B34A",
                      maxWidth: "100%",
                      display: "flex",
                      p: "1em",
                      gap: "1rem",
                    }}
                  >
                    <Ellipse />
                    <Box display="block">
                      <Typography sx={{ color: "#fff" }}>
                        Faturamento Aprovado: {""}
                        <br />
                      </Typography>
                      <Typography sx={{ color: "#21512", fontSize: "1.2rem" }}>
                        {NumberFormat(valores["aprovados"]?.total)}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card
                  sx={{
                    backgroundColor: "#F2F2F2",
                    maxWidth: "100%",
                    display: "block",
                    mt: "1rem",
                  }}
                >
                  <FolderList />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LayoutBaseDePagina>
      <Outlet />
    </>
  );
};
