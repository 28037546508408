/* eslint-disable indent */
import { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Icon,
  Paper,
  TextField,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";

import { LayoutBaseDePagina } from "../../shared/layouts";
import { OrcamentoService } from "../../shared/services/api/orcamento/OrcamentoService";
import {
  ListaAgrupamento,
  ListaOrcamentos,
} from "../../shared/components/lista-orcamentos";

import { TabsComponent } from "../../shared/components/tabs/TabsComponent";

enum Disclaimer {
  aprovados = "Total de Orçamentos",
  enviados = "Total de Orçamentos",
  pendentes = "Veículos estão aguardando orçamento",
}

export const Orcamentos = () => {
  const [orcamentos, setOrcamentos] = useState<any>([]);
  const [agrupamento, setAgrupamento] = useState<any>([]);
  const [termoError, setTermoError] = useState("");
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [termo, setTermo] = useState("");
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams<"status" | "id_reparo">();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  useEffect(() => {
    const localUser = localStorage.getItem("app-oficinas__user") || false;
    const localOficina = localStorage.getItem("app-oficinas__oficina");
    const isDadosOficina = !!localOficina ?? false;
    const isTabParams = searchParams.has("tab");

    (async () => {
      if (localUser) {
        const dataUser = JSON.parse(localUser);

        if (isDadosOficina) {
          await handleDadosOrcamentos(
            dataUser?.users?.id,
            isTabParams ? searchParams.get("tab") || "" : params?.status
          );
        }
      }
    })();
  }, [searchParams]);

  const handleDadosOrcamentos = async (user_id: number, tab?: string) => {
    setIsLoading(true);
    await OrcamentoService.getReparosComOrcamento(user_id, tab || "")
      .then((res) => {
        if (res instanceof Error) {
          setOrcamentos([]);
        } else {
          setTotal(res?.total || 0);
          setAgrupamento(res?.agrupamento || {});
          setOrcamentos(res?.data || {});
        }

        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDiclaimer = (status: string | undefined) => {
    switch (status) {
      case "aprovados": {
        return Disclaimer["aprovados"];
      }
      case "enviados": {
        return Disclaimer["enviados"];
      }
      case "pendentes": {
        return Disclaimer["pendentes"];
      }
      default:
        return "";
    }
  };

  const handleOnChange = (termo: string) => {
    if (termo.length > 4) {
      setIsSearchDisabled(false);
    } else {
      setIsSearchDisabled(true);
    }
    setTermo(termo);
  };

  const handlePesquisar = async () => {
    setIsLoading(true);
  };

  return (
    <>
      <LayoutBaseDePagina titulo="Dashboard" barraDeFerramentas={null}>
        <Box width="100%" display="flex">
          <Grid container margin={2}>
            <Grid item container spacing={2}>
              {!searchParams.has("tab") && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ mt: "0 !important", pt: "0 !important" }}
                >
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ m: 0, mt: 2, color: "#2C2646" }}
                  >
                    Orçamentos {params?.status}
                  </Typography>
                  {total > 0 && (
                    <>
                      <Typography
                        variant="h2"
                        align="center"
                        sx={{ m: 0, mt: 2, color: "#000" }}
                      >
                        {total}
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{ mx: 5, mt: 2, color: "#908BA6" }}
                      >
                        {getDiclaimer(params?.status)}
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
              {isLoading ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Skeleton height={52} />
                  <Skeleton height={36} />
                  <Skeleton height={36} />
                  <Skeleton height={36} />
                  <Skeleton height={36} />
                  <Skeleton height={36} />
                </Grid>
              ) : (
                orcamentos && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {searchParams.has("tab") && (
                      <TabsComponent
                        selected={searchParams.get("tab") || ""}
                        tabs={[
                          { name: "Analise", value: "analise" },
                          { name: "Negados", value: "recusados" },
                        ]}
                      />
                    )}
                    {!searchParams.has("tab") && (
                      <Box
                        padding={1}
                        display="flex"
                        alignItems="center"
                        height={theme.spacing(6)}
                        sx={{
                          borderRadius: "2px !important",
                          my: theme.spacing(4),
                        }}
                      >
                        <TextField
                          fullWidth
                          type="termo"
                          value={termo}
                          placeholder="Buscar por Placa..."
                          disabled={isLoading}
                          error={!!termoError}
                          helperText={termoError}
                          onKeyDown={() => setTermoError("")}
                          onChange={(e) => handleOnChange(e.target.value)}
                          sx={{ mr: 2, borderRadius: "2px !important" }}
                        />
                        <Box flex={1} display="flex" justifyContent="end">
                          <Button
                            variant="contained"
                            disabled={isSearchDisabled}
                            onClick={handlePesquisar}
                            endIcon={<Icon>search</Icon>}
                            sx={{
                              color: "#fff",
                              backgroundColor: "#36B34A",
                              padding: 2,
                              margin: 0,
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    <Card>
                      <CardContent
                        sx={{
                          px: "16px",
                          py: "8px",
                          backgroundColor: "#FCFCFD",
                        }}
                      >
                        {agrupamento.length &&
                        params?.status === "enviados" &&
                        !searchParams.has("tab") ? (
                          <ListaAgrupamento data={agrupamento} />
                        ) : (
                          orcamentos?.map((item: any, index: 0) => {
                            return (
                              <ListaOrcamentos
                                data={item}
                                queryUrl={params?.status}
                                key={index}
                              />
                            );
                          })
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </LayoutBaseDePagina>
      <Outlet />
    </>
  );
};
