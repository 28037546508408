import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

export type BackdropProps = {
  open: boolean;
  onClick: () => void;
};

export const Loading = ({ open, onClick }: BackdropProps) => {
  return(
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={onClick}
    >
      <CircularProgress color="inherit" />
    </Backdrop>   
  );
};
