import {
  Divider,
  Drawer,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { Box } from "@mui/system";

import { useAuthContext, useDrawerContext } from "../../contexts";
interface Props {
  children: React.ReactNode;
}
interface IListItemLinkProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}

const ListItemLink: React.FC<IListItemLinkProps> = ({
  to,
  icon,
  label,
  onClick,
}) => {
  const navigate = useNavigate();

  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <ListItemButton selected={!!match} onClick={handleClick}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

export const MenuLateral: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const { isDrawerOpen, drawerOptions, toggleDrawerOpen } = useDrawerContext();
  const { isAuthenticated } = useAuthContext();

  const logout = () => {
    return;
  };

  return (
    <>
      {isAuthenticated && (
        <Drawer
          open={isDrawerOpen}
          variant={smDown ? "temporary" : "permanent"}
          onClose={toggleDrawerOpen}
        >
          <Box
            width={theme.spacing(28)}
            height="100%"
            display="flex"
            flexDirection="column"
          >
            {/* <Box width="100%" height={theme.spacing(20)} display="flex" alignItems="center" justifyContent="center">
              {}
            </Box> */}

            <Divider />

            <Box flex={1}>
              <List component="nav">
                {drawerOptions.map((drawerOption) => (
                  <ListItemLink
                    to={drawerOption.path}
                    key={drawerOption.path}
                    icon={drawerOption.icon}
                    label={drawerOption.label}
                    onClick={smDown ? toggleDrawerOpen : undefined}
                  />
                ))}
              </List>
            </Box>

            <Box>
              <List component="nav">
                <ListItemButton onClick={logout}>
                  <ListItemIcon>
                    <Icon color="error">logout</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary="Sair"
                    sx={{
                      color: "#D14343 !important",
                      fontWeight: "600 !important",
                    }}
                  />
                </ListItemButton>
              </List>
            </Box>
          </Box>
        </Drawer>
      )}

      <Box
        height="100vh"
        marginLeft={smDown ? 0 : isAuthenticated ? theme.spacing(28) : 0}
      >
        {children}
      </Box>
    </>
  );
};
