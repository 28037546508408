import type { SharedConfig } from "./env";

const sharedConfig: SharedConfig = {
  app: {
    enviromment: process.env.NODE_ENV || "",
    url: "http://localhost:3000/" || "",
    line: 5,
    search: "Pesquisar...",
    searchNotFound: "Nenhum registro encontrado.",      

  },
  bff: {
    baseUrl:  process.env.REACT_APP_API_PORTAL || "",
    grantType: "password",
    clientId: process.env.REACT_APP_PORTAL_CLIENT_ID || "",
    clientSecret: process.env.REACT_APP_PORTAL_CLIENT_SECRET || "",
    scope: "",
  },
};

export default sharedConfig;
