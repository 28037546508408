import { appService, builder } from "../axios-config";

const enviarArquivos = async (token: string, id: number, dados: FormData): Promise<any | Error> => {
  try {
    const { data } = await appService.post(
      builder(`reparo/${id}/upload_files`),
      dados,
      { 
        headers:
          {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          }
      }
    );

    if (data) {
      return data;
    }

    return new Error("Erro ao enviar arquivos.");
  } catch (error) {

    return new Error((error as { message: string }).message || "Erro ao enviar os dados.");
  }  
};


export const UploadService = {
  enviarArquivos
};
