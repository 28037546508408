import { useState } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";

export default function FooterNavigation() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  async function handleLogout() {
    navigate("/logout", {replace: true});
  }

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ width: smDown ? 500 : "100%" }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          component={Link}
          label="Home"
          to='/dashboard'
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="/check/in"
          label="Buscar"
          icon={<SearchIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="/404"
          label="Configurações"
          icon={<EngineeringIcon />}
        />
        <BottomNavigationAction
          onClick={handleLogout}
          label="Sair"
          icon={<Logout />}
        />        
      </BottomNavigation>
    </Box>
  );
}
