import { AxiosError } from "axios";


export const onError = (error: AxiosError) => {

  if (error.message === "Network Error") {
    return Promise.reject(new Error("Erro de conexão."));
  }

  if (error.response?.status === 401) {
    return Promise.reject(new Error("Login ou senha estão inválidos. Por favor digite corretamente!"));
  }

  return Promise.reject(error);
};
