import { ReactNode } from "react";
import { Theme, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";

import Header from "../components/header/Header";
import FooterNavigation from "../components/footer/FooterNavigation";

interface ILayoutBaseDePaginaProps {
  titulo: string;
  barraDeFerramentas?: ReactNode;
  children?: ReactNode;
}
export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({ children, barraDeFerramentas }) => {
  const isBarra = !barraDeFerramentas;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const theme = useTheme();

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column" gap={1}>
        <Box padding='20px 1em 0' display="flex" justifyContent='space-between' height={theme.spacing(smDown ? 4 : mdDown ? 8 : 10)}>
          <Header />
        </Box>

        {isBarra && (
          <Box>
            {barraDeFerramentas}
          </Box>
        )}

        <Box flex={1} overflow="auto">
          {children}
        </Box>
        <Box padding={1} display="flex" alignItems="center" gap={1} height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}>
          <FooterNavigation />
        </Box>
      </Box>
    </>
  );
};
