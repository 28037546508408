import * as React from "react";
import { useSearchParams } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabsList {
  name?: string;
  value?: string;
}

interface TabsProps {
  tabs?: TabsList[];
  selected?: string;
}

export const TabsComponent: React.FC<TabsProps> = ({ tabs, selected }) => {
  const [value, setValue] = React.useState(selected);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchParams(`?${new URLSearchParams({ tab: newValue })}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="Lista de Orçamentos"
      >
        {tabs?.map((item, index) => {
          return <Tab value={item.value} label={item.name} key={index} />;
        })}
      </Tabs>
    </Box>
  );
};
