import * as React from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { LayoutBaseDePagina } from "../../shared/layouts";

import emManutencao from "../../assets/images/em-manutencao.png";

export const PageNotFound = () => {

  return (
    <LayoutBaseDePagina
      titulo='Dashboard'
      barraDeFerramentas={null}
    >
      <Box width='100%' display='flex'>
        <Grid container margin={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>                    
                    <Typography variant='subtitle1' color='text.secondary' component='div'>
                        Página em manutenção!
                    </Typography>
                  </CardContent>
                </Box>
                <img
                  src={emManutencao}
                  alt='Saiba como realizar o checkin'
                  style={{ textAlign: "center", width: "20%", height:"20%", padding: "10px", margin: 1, marginTop: 10, display: "block" }}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LayoutBaseDePagina>
  );
};
