import sharedConfig from "./shared";
import type { Config } from "./env";

const { app, ...shared } = sharedConfig;

const config: Config = {
  app: {
    ...app,
  },
  ...shared,
};

export default config;
