import * as React from "react";
import { Box, Icon, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { green } from "@mui/material/colors";
import { Link } from "react-router-dom";

import { LogoHeader } from "../logo/logo-header";
import { useDrawerContext } from "../../contexts";

export default function Header() {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { toggleDrawerOpen } = useDrawerContext();
  return (
    <>
      {smDown && (
        <IconButton onClick={toggleDrawerOpen}>
          <Icon>menu</Icon>
        </IconButton>
      )}
      <LogoHeader />

      <Box display="flex">
        <IconButton component={Link} to="/404">
          <Icon
            sx={{
              color: green[800],
              fontSize: 26,
            }}
          >
            notifications_none_sharp
          </Icon>
        </IconButton>

        <IconButton component={Link} to="/404">
          <Icon
            sx={{
              color: green[800],
              fontSize: 26,
            }}
          >
            account_circle
          </Icon>
        </IconButton>
      </Box>
    </>
  );
}
