import { RouterProvider } from "react-router-dom";

import "./shared/forms/TraducoesYup";

import { AppThemeProvider, AuthProvider, DrawerProvider } from "./shared/contexts";
import { MenuLateral } from "./shared/components";
import { routes } from "./routes";

export const App = () => {

  return (
    <AuthProvider>
      <AppThemeProvider>        
        <DrawerProvider>
          <MenuLateral>
            <RouterProvider router={routes} />
          </MenuLateral>
        </DrawerProvider>
      </AppThemeProvider>
    </AuthProvider>
  );
};
