import { Link } from "react-router-dom";
import { Box, Button, Card, CardContent, Container, Typography} from "@mui/material";
import bgBanner from "../../assets/images/bg-carro.png";

export const Welcome = () => {

  return (
    <>
      <Box
        width='99.9%' height='100%' alignItems='center' justifyContent='center'
        component="main"
        sx={{
          backgroundColor: "#FFFFFF",
          flexGrow: 1,
          minHeight: "100%"
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            padding: "0 0 50px 0",
            minHeight: "100vh",
            
          }}
        >
          <img src={bgBanner} alt='Seja bem-vindo!' style={{ width: "100%", margin: "auto", display: "block" }} />
          <Card sx={{
            borderRadius: "10px 10px 0px 0px",
            boxShadow: "0",
            position: "relative",
            top: "-20px",
            borderButtomRadius: "0"
          }}>
            <CardContent sx={{px: "40px"}}>
              <Typography sx={{fontSize: "32px", fontWeight: "700", color: "#3FAD4E", mt: 1}}
              >
                Boas-Vindas
              </Typography>
              <Typography sx={{fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#000", mt: 1}}>
                Esta é uma área de <b>acesso exclusivo</b> para <b>Ofinas Credenciadas</b>.
              </Typography>
              <Typography sx={{fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#000", mt: 1}}>
                Faça login para ter acesso a todas funcionalidades do sistema <strong>Gestauto</strong>.
              </Typography>
              <Button
                size="large"
                variant='contained'
                component={Link}
                to='/login'
                sx={{
                  width: "100%",
                  fontSize: "1.1rem",
                  mt: 2
                }}
              >
                Login
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};
