
import React, { useState, useEffect } from "react";
import { Box, Grid, Container} from "@mui/material";

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { LogoLogin } from "../../shared/components/logo/logo-login";

export const Logout = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    Cookies.remove("app-oficinas__Token");
    navigate("/login", {replace: true});
  }, []);

  return (
    <>     
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          minHeight: "100%"
        }}
      >
        <Grid>
          <Box
            alignSelf='center'
            justifyContent='center'
            sx={{
              minHeight: "20vh",
            }}
          >
            <LogoLogin />
          </Box>
                    
        </Grid>
      </Container>
    </>
  );    
};
