import { createTheme } from "@mui/material";

const theme = createTheme({
});

export const GestautoTheme = createTheme(theme, {
  breakpoints: {
    values: {
      xs: 0,
      sm: 580,
      md: 1000,
      lg: 1200,
      xl: 1920
    }
  },
  components: {
    root: {
      fontFamily: '"Sora", "Roboto", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important',
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Sora", "Roboto", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important',
        },
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          backgroundColor: "#3FAD4E",
          fontSize: "1.2rem",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#3FAD4E",
            color: "#fff",
          },
        },
        sizeSmall: {
          padding: "6px 16px"
        },
        sizeMedium: {
          padding: "8px 20px"
        },
        sizeLarge: {
          padding: "12px 24px"
        },
        textSizeSmall: {
          padding: "7px 12px"
        },
        textSizeMedium: {
          padding: "9px 16px"
        },
        textSizeLarge: {
          padding: "12px 16px"
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#FAD22D"
          },
          "& .MuiButton-root": {
            "&:hover": {
              backgroundColor: "#FAD22D"
            },            
          },
        },
      },
      defaultProps: {
        disableRipple: true
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "#284614 !important",
          "&:hover": {
            fontWeight: 600
          },
          "& .Mui-selected": {
            color: "#284614 !important",
            fontWeight: 600
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "32px 24px",
          "&:last-child": {
            paddingBottom: "32px"
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6"
        },
        subheaderTypographyProps: {
          variant: "body2"
        }
      },
      styleOverrides: {
        root: {
          padding: "32px 24px"
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          fontFamily: '"Sora", "Roboto", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important',
          boxSizing: "border-box",
          margin: 0,
          padding: 0
        },
        html: {
          fontFamily: '"Sora", "Roboto", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important',
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%"
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: '"Sora", "Roboto", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important',
        },
        notchedOutline: {
          borderColor: "#000 !important"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#F3F4F6",
          ".MuiTableCell-root": {
            color: "#374151"
          },
          borderBottom: "none",
          "& .MuiTableCell-root": {
            borderBottom: "none",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: "uppercase"
          },
          "& .MuiTableCell-paddingCheckbox": {
            paddingTop: 4,
            paddingBottom: 4
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "& .MuiTabs-scroller": {
            "& .MuiTabs-flexContainer": {
              "& .MuiButtonBase-root": {
                color: "#433E5B",
                fontSize: ".9rem !important",
              },
              "& .MuiButtonBase-root-MuiTab-root": {
                lineHeight: "none !important",
                letterSpacing: 0.5,
                padding: "10px 16px",
                minHeight: "35px"
              },
              "& .Mui-selected": {
                color: "#ffffff",
                backgroundColor: "#36B34A",
                borderRadius: "15px",
                fontWeight: 300,
              }
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent !important",
            }
          },
          borderBottom: "none",   
        }
      }
    },    
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "5px !important",
          borderColor: "#EAE8F0",
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff !important",
          lineHeight: 2,
          "&:after": {
            borderBottomColor: "#00B63F !important"
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#000 !important",
          fontSize: "1.2rem !important",          
          fontWeight: 700,
          "& .MuiInputLabel": {
            root: {
              "& .Mui-focused": {
                color: "#284614 !important"
              }
            }
          }          
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: "1.2rem",
        }
      }
    },
  },
  palette: {
    action: {
      active: "#6B7280",
      focus: "rgba(55, 65, 81, 0.12)",
      hover: "rgba(55, 65, 81, 0.04)",
      selected: "rgba(55, 65, 81, 0.08)",
      disabledBackground: "rgba(55, 65, 81, 0.12)",
      disabled: "rgba(55, 65, 81, 0.26)"
    },
    background: {
      default: "#fff",
      paper: "#FFFFFF",
    },
    divider: "#E6E8F0",
    primary: {
      main: "#5048E5",
      light: "#828DF8",
      dark: "#3832A0",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#10B981",
      light: "#3FC79A",
      dark: "#284614",
      contrastText: "#FFFFFF"
    },
    success: {
      main: "#14B8A6",
      light: "#43C6B7",
      dark: "#0E8074",
      contrastText: "#FFFFFF"
    },
    info: {
      main: "#2196F3",
      light: "#64B6F7",
      dark: "#0B79D0",
      contrastText: "#FFFFFF"
    },
    warning: {
      main: "#FFB020",
      light: "#FAD22D",
      dark: "#B27B16",
      contrastText: "#FFFFFF"
    },
    error: {
      main: "#D14343",
      light: "#DA6868",
      dark: "#922E2E",
      contrastText: "#FFFFFF"
    },
    text: {
      primary: "#121828",
      secondary: "#284614",
      disabled: "rgba(55, 65, 81, 0.48)"
    },
    salmon: theme.palette.augmentColor({
      color: {
        main: "#FF5733",
      },
      name: "salmon",
    }),
  },
  shape: {
    borderRadius: 8
  },
  shadows: [
    "none",
    "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
    "0px 1px 2px rgba(100, 116, 139, 0.12)",
    "0px 1px 4px rgba(100, 116, 139, 0.12)",
    "0px 1px 5px rgba(100, 116, 139, 0.12)",
    "0px 1px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 6px rgba(100, 116, 139, 0.12)",
    "0px 3px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
    "0px 5px 12px rgba(100, 116, 139, 0.12)",
    "0px 5px 14px rgba(100, 116, 139, 0.12)",
    "0px 5px 15px rgba(100, 116, 139, 0.12)",
    "0px 6px 15px rgba(100, 116, 139, 0.12)",
    "0px 7px 15px rgba(100, 116, 139, 0.12)",
    "0px 8px 15px rgba(100, 116, 139, 0.12)",
    "0px 9px 15px rgba(100, 116, 139, 0.12)",
    "0px 10px 15px rgba(100, 116, 139, 0.12)",
    "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 14px 24px -8px rgba(100, 116, 139, 0.25)",
    "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)"
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Sora", "Roboto", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important',
    button: {
      fontWeight: 600,
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.57,
      letterSpacing: "0.02857em",
      color: "rgb(99, 115, 129)",
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase"
    },
    caption: {
      color: "rgb(123, 128, 154)",
      fontSize: "0.75rem",
      fontWeight: 300,
      lineHeight: 1.25
    },
    h1: {
      fontWeight: 700,
      fontSize: "3.5rem",
      lineHeight: 1.375
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.375
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.375
    },
    h4: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.375,
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.375,     
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: 1.375,
    },
  }
});
