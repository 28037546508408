import { createContext, useContext, useMemo } from "react";
import { ThemeProvider } from "@mui/material";
import { Box } from "@mui/system";

import { GestautoTheme } from "./../themes";

interface IThemeContextData {
  themeName: "light";
}
interface Props {
  children: React.ReactNode;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};

export const AppThemeProvider: React.FC<Props> = ({ children }) => {
  const themeName = "light";

  const theme = useMemo(() => {
    return GestautoTheme;
  }, [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName }}>
      <ThemeProvider theme={theme}>
        <Box height="100%" bgcolor={theme.palette.background.default}>
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
