import * as React from "react";

import { Box } from "@mui/material";

import headerLogo from "../../../assets/images/logos/logotipo-h-v2.png";

export const LogoHeader: React.FC = () => (
  <Box display='flex' alignItems='center' justifyContent='center'>
    <img src={headerLogo} width={"90%"} />
  </Box>
);
